<template>
  <div>
    <ServerError v-if="ServerError" />
    <PageLoader v-bind:storage="appLoading" />
    <v-snackbar v-model="showSnackBar" color="#3CB043" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog v-model="dialog" width="700" persistent>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-card tile flat>
            <v-layout
              wrap
              justify-center
              px-8
              py-5
              style="background-color: #b6b6b626"
            >
              <v-flex xs11 text-left>
                <span
                  style="
                    font-family: poppinssemibold;
                    font-size: 18px;
                    color: #393939;
                  "
                >
                  Edit Profle
                </span>
              </v-flex>
              <v-flex xs1>
                <v-icon @click="dialog = false" color="#000000"
                  >mdi-close</v-icon
                >
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              justify-center
              pt-8
              px-5
              style="
                font-size: 14px;
                color: #1f1f1f;
                font-family: RobotoRegular;
              "
            >
              <v-flex xs12 sm6 text-left px-2>
                <span>Full Name </span>
                <v-text-field
                  ref="fullname"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  :rules="[rules.required]"
                  v-model="profile.name"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 text-left px-2>
                <span> Phone </span>
                <v-text-field
                  ref="phone"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  readonly
                  :rules="[rules.required]"
                  v-model="profile.phone"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 text-left px-2>
                <span> Email </span>
                <v-text-field
                  ref="email"
                  background-color="#F5F5F5"
                  solo
                  flat
                  dense
                  :rules="[rules.required]"
                  v-model="profile.email"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 text-left px-2>
                <span> Gender </span>
                <v-radio-group v-model="profile.gender" mandatory>
                  <v-layout wrap>
                    <v-flex>
                      <v-radio
                        color="#30B868"
                        small
                        label="Male"
                        value="Male"
                      ></v-radio>
                    </v-flex>
                    <v-flex>
                      <v-radio
                        color="#30B868"
                        label="Female"
                        value="Female"
                      ></v-radio>
                    </v-flex>
                    <v-flex>
                      <v-radio
                        color="#30B868"
                        label="Other"
                        value="Other"
                      ></v-radio>
                    </v-flex>
                  </v-layout>
                </v-radio-group>
              </v-flex>
              <v-flex xs12 sm6 text-left px-2>
                <span> Date of Birth </span>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="profile.dob"
                      background-color="#F5F5F5"
                      solo
                      flat
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="profile.dob"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-end py-5>
              <v-flex xs6 px-2 text-right>
                <v-btn
                  :ripple="false"
                  depressed
                  block
                  tile
                  color="#30B868"
                  @click="editProfile()"
                >
                  <span
                    style="
                      font-family: poppinssemibold;
                      font-size: 12px;
                      color: #fff;
                      text-transform: none;
                    "
                  >
                    Edit Profile
                  </span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-dialog>
    <v-layout wrap justify-center>
      <v-flex xs11 pt-5>
        <v-layout wrap>
          <v-flex xs12 text-left>
            <span
              style="
                color: #1f1f1f;
                font-family: poppinssemibold;
                font-size: 18px;
              "
              >Profile Details</span
            >
          </v-flex>
          <v-flex xs12 pt-5>
            <template>
              <v-progress-linear
                value="100"
                color="#30B868"
              ></v-progress-linear>
            </template>
          </v-flex>
        </v-layout>
        <v-layout wrap style="background-color: #fbfbfb" pb-5 pt-10>
          <v-flex xs12>
            <v-layout wrap pl-xs-0 pl-sm-0 pl-md-10 pl-lg-10 pl-xl-10>
              <v-flex xs12 sm6 md4 pl-11 text-left>
                <span class="content1">Full name</span>
              </v-flex>
              <v-flex xs12 sm6 md8 text-left pl-11 pl-xs-11 pl-sm-0 pl-md-0 pl-lg-0 pl-xl-0>
                <span class="content2">{{ profile.name }} </span>
              </v-flex>
            </v-layout>
            <v-layout wrap py-5 pl-xs-0 pl-sm-0 pl-md-10 pl-lg-10 pl-xl-10>
              <v-flex xs12 sm6 md4 pl-11 text-left>
                <span class="content1">Mobile number</span>
              </v-flex>
              <v-flex xs12 sm6 md8 text-left pl-11 pl-xs-11 pl-sm-0 pl-md-0 pl-lg-0 pl-xl-0>
                <span class="content2">{{ profile.phone }}</span>
              </v-flex>
            </v-layout>
            <v-layout wrap pl-xs-0 pl-sm-0 pl-md-10 pl-lg-10 pl-xl-10>
              <v-flex xs12 sm6 md4 pl-11 text-left>
                <span class="content1">Email</span>
              </v-flex>
              <v-flex xs12 sm6 md8 text-left pl-11 pl-xs-11 pl-sm-0 pl-md-0 pl-lg-0 pl-xl-0>
                <span class="content2">{{ profile.email }}</span>
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              py-5
              v-if="profile.gender"
              pl-xs-0
              pl-sm-0
              pl-md-10
              pl-lg-10
              pl-xl-10
            >
              <v-flex xs12 sm6 md4 pl-11 text-left>
                <span class="content1">Gender</span>
              </v-flex>
              <v-flex xs12 sm6 md8 text-left pl-11 pl-xs-11 pl-sm-0 pl-md-0 pl-lg-0 pl-xl-0>
                <span class="content2">{{profile.gender}}</span>
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              v-if="profile.gender"
              pl-xs-0
              pl-sm-0
              pl-md-10
              pl-lg-10
              pl-xl-10
            >
              <v-flex xs12 sm6 md4 pl-11 text-left>
                <span class="content1">Date of Birth</span>
              </v-flex>
              <v-flex xs12 sm6 md8 text-left pl-11 pl-xs-11 pl-sm-0 pl-md-0 pl-lg-0 pl-xl-0>
                <span class="content2">{{profile.dob}}</span>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-end pr-10 pt-5>
              <v-flex xs12 align-self-end text-right>
                <v-btn color="#30B868" dark depressed @click="dialog = true">
                  <span
                    style="
                      font-family: opensansregular;
                      text-transform: none;
                      font-size: 13px;
                    "
                    >Edit Profile</span
                  ></v-btn
                >
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      dialog: false,
      menu1: false,
      profile: {},
      name: "",
      phone: "",
      email: "",
      gender: "",
      dob: null,
      rules: {
        required: (value) => !!value || "Required.",
        min: (value) => value.length >= 8 || "Min 8 characters",
      },
    };
  },
  beforeMount() {
    this.getUser();
  },
  methods: {
    getUser() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/profile",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.profile = response.data.data;
            this.profile.dob = this.profile.dob.slice(0, 10);
          }
        })
        .catch((err) => {
          console.log(err);
          this.appLoading = false;
        });
    },
     editProfile() {
      this.appLoading = true;
      axios({
        url: "/editProfile",
        method: "POST",
        data: {
          name: this.profile.name,
          phone: this.profile.phone,
          email: this.profile.email,
          gender: this.profile.gender,
          dob: this.profile.dob,
        },
        headers: { token: localStorage.getItem("token") },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.dialog = false;
            this.getUser();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.content1 {
  color: #000000;
  font-family: poppinsregular;
  text-align: left;
}

.content2 {
  color: #000000;
  font-family: poppinsmedium;
}
</style>